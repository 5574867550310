import React from 'react';
import './Footer.css'
import FooterLogo from '../../Assets/Images/logo.svg'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import LocationIcon from '../../Assets/Images/Icons/LocateUsOnMap.png'
import WhatsAppIcon from '../../Assets/Images/Icons/ConnectUsonWhatsapp.png'


function Footer() {
  return (
    <div className='Footer'>
      <div className='Footer-list-content' >
        <div className='Footer-logo'>
          <img src={FooterLogo} alt="" />
        </div>
        <div className='footer-links' >
          <div className='footer-social-icons'>
            <div className='social-heading' >
              <h3>Social</h3>
            </div>
            <div className='social-links' >
              <p > <a style={{ textDecoration: "none", color: "white", fontFamily: "DM Sans18ptLight" }} href="https://www.linkedin.com/company/d-webbox/mycompany/" target="_blank" >LINKEDIN</a> </p>
              <p> <a style={{ textDecoration: "none", color: "white", fontFamily: "DM Sans18ptLight" }} href="https://www.instagram.com/dwebbox/" target="_blank" > INSTAGRAM</a></p>
              <p> <a style={{ textDecoration: "none", color: "white", fontFamily: "DM Sans18ptLight" }} href="https://www.facebook.com/p/DWebBox-100054804518286/" target="_blank" >FACEBOOK</a></p>
              {/* <p> <a style={{textDecoration:"none",color:"white",fontFamily:"DM Sans18ptLight"}}  href="" target="_blank" > UPWORK</a></p> */}
            </div>
          </div>
          <div className='footer-Address'>
            <h3 className='address-heading' >Address</h3>
            <p className='address-paragraph'>70, Ratnajyot Industrial Estate,
              2nd Floor, Irla Lane,
              Vile Parle (W), Mumbai, Maharashtra 400056</p>
            <div className='LocationIcon-img' >
              <a href="https://www.google.com/maps/dir//Ratnajyot+Industrial+Premises,+KLS+Hospital,+Irla+Gaothan,+Navpada,+Irla,+Vile+Parle+West,+Mumbai,+Maharashtra/@19.1086549,72.8010276,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be7c98d52226bab:0xe6852ed284119f7c!2m2!1d72.8422275!2d19.1085784!5m1!1e4?hl=en-IN&entry=ttu&g_ep=EgoyMDI1MDQwNi4wIKXMDSoASAFQAw%3D%3D" target='_blank' >
                <img src={LocationIcon} alt="" width="50%" />
              </a>
            </div>

          </div>
          <div className='Footer-Contact-information-number' >
            <div className='social-heading' >
              <h3 style={{ color: "#ffffff" }} >Contact</h3>
            </div>
            <div className='mobile-number-section-main' >
              <p className='mobile-number'><a href="tel:+918828103808" >+91 8828103808</a></p>
              <p className='mobile-number-second'><a href="tel:8484946567">+91 8484946567</a></p>
              <p className='mobile-number-second'><a href="tel:9167871033">+91 9167871033</a></p>
            </div>

            <div className='WhatsAppIcon-FooterImg' >
              <a href="https://api.whatsapp.com/send?phone=+918828103808&text=Hello" target="_blank" >
                <img src={WhatsAppIcon} alt="" width="50%" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='connect-dewebbox'>
        <div className='collab-section'>
          <h2>Let's collaborate.</h2>
          <div className='arrow-heading' >
            <h1><a href="mailto:connect@dwebbox.com">connect@dwebbox.com</a></h1>
            <ArrowOutwardIcon className='arrow-outward-icon' sx={{ color: "white", fontSize: "50px", fontWeight: "700", marginTop: "5px" }} />
          </div>


        </div>
      </div>

      <div className="straight-line-footer"></div>

      <div className='footer-para'>
        <p>© 2024 D-WEBBOX IT SOLUTIONS Pvt. Ltd.  <span className='footer-span' >All rights reserved. </span> </p>
      </div>
    </div>
  )
}

export default Footer
